import { NgModule, Injectable } from "@angular/core";
import { Routes, RouterModule, UrlTree, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import {
  PasswordDialogComponent,
  AuthGuardService,
  DevicesComponent,
  DeviceDetailsComponent,
  DashboardUsersComponent,
  AdminAuthGuard,
  AppAdminComponent,
  AlertsComponent,
  AlertsHistoryComponent,
  NotFoundComponent,
  InsightsComponent,
} from "@walabot-mqtt-dashboard/ui";
import { map } from "rxjs/operators";

@Injectable()
export class ConfigurationGuard implements CanActivate {
  constructor(private dialog: MatDialog) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: "250px",
      data: { match: "HospitalDemo" },
    });
    return dialogRef.afterClosed().pipe(
      map((result: { [key: string]: boolean }) => {
        if (result.canceled) {
          return false;
        }
        return true;
      }),
    );
  }
}
const monitorRoutes: Routes = [
  {
    path: "alerts",
    component: AlertsComponent,
    data: { title: "Alerts", alertsEnabled: true },
    canActivate: [AuthGuardService],
  },
  {
    path: "history",
    component: AlertsHistoryComponent,
    data: { title: "History" },
    canActivate: [AuthGuardService],
  },
];
const routes: Routes = [
  {
    path: "monitor",
    children: [
      ...monitorRoutes,
      {
        path: "",
        pathMatch: "full",
        redirectTo: "alerts",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "installer",
    children: [
      ...monitorRoutes,
      {
        path: "",
        pathMatch: "full",
        redirectTo: "alerts",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "admin",
    children: [
      ...monitorRoutes,
      {
        path: "insights",
        component: InsightsComponent,
        data: { title: "Insights", isSuiteView: false },
        canActivate: [AuthGuardService],
      },
      {
        path: "insights/:suiteId",
        component: InsightsComponent,
        data: { title: "Insights", isSuiteView: true },
        canActivate: [AuthGuardService],
      },
      {
        path: "admin",
        component: AppAdminComponent,
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: "devices",
            component: DevicesComponent,
            data: { title: "Devices" },
            canActivate: [AdminAuthGuard],
          },
          {
            path: "device/:id",
            component: DeviceDetailsComponent,
            canActivate: [AdminAuthGuard],
          },
          {
            path: "dashboard-users",
            component: DashboardUsersComponent,
            data: { title: "Dashboard Users", roles: ["admin"] },
            canActivate: [AdminAuthGuard],
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "devices",
          },
        ],
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "alerts",
      },
      {
        path: "**",
        pathMatch: "full",
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: "**",
    pathMatch: "full",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
  providers: [ConfigurationGuard],
})
export class AppRoutingModule {}
